import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Select,
  Radio,
  Input,
  Space,
  Tag,
  Divider,
  DatePicker,
} from 'antd';
import type { SelectProps, RadioChangeEvent } from 'antd';

import { Card } from 'src/components';
import { urgencyDegree, urgencyDegreeColor } from 'src/utils/constants/tasks';
import { IReduxState } from 'src/store';

import { IProps } from './form.type';
import { rules } from './form.utils';

const { TextArea } = Input;

type TagRender = SelectProps['tagRender'];

const FormComponent: React.FC<IProps> = (props) => {
  const { operators, patrimonies } = props;

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const user = useSelector((state: IReduxState) => state.auth?.user);

  const options = [
    { label: 'Corte', value: 38 },
    { label: 'Costura', value: 37 },
    { label: 'Sublimação', value: 35 },
    { label: 'Preparação', value: 'Preparação' },
    { label: 'Pilotagem', value: 'Pilotagem' },
    { label: 'Expedição', value: 36 },
  ];

  const tagRender: TagRender = (props: any) => {
    const { label, value } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag color={urgencyDegreeColor[value]} onMouseDown={onPreventMouseDown}>
        <b>{label}</b>
      </Tag>
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Card>
      <Row gutter={16}>
        <Col span={24} lg={14}>
          <Form.Item label="Setor:" name="sectorId">
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              buttonStyle="solid"
              optionType="button"
            />
          </Form.Item>
          <Form.Item label="Células:" name="cellId">
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio value={1}>
                  <b>CEL 100 SH</b>
                </Radio>
                <Radio value={2}>
                  <b>CEL 200 SH</b>
                </Radio>
                <Radio value={3}>
                  <b>CEL 300 BERM</b>
                </Radio>
                <Radio value={4}>
                  <b>CEL 400 BERM / REC</b>
                </Radio>
                <Radio value={5}>
                  <b>CEL 600 SH ESPECIAL</b>
                </Radio>
                <Radio value={9}>
                  Outro:
                  {value === 9 ? (
                    <Input style={{ width: 100, marginLeft: 10 }} />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} lg={10}>
          <Form.Item
            label="Motivo:"
            name="description"
            rules={rules.description}
          >
            <TextArea placeholder="Motivo" rows={4} />
          </Form.Item>

          <Form.Item
            label="Patrimônio (Máquina):"
            name="patrimonyId"
            rules={rules.urgencyDegree}
          >
            <Select
              showSearch
              placeholder="Patrimônio (Máquina)"
              optionFilterProp="children"
              filterOption={filterOption}
              options={patrimonies}
            />
          </Form.Item>

          <Form.Item
            label="Prioridade (Bandeira):"
            name="urgencyDegree"
            rules={rules.urgencyDegree}
          >
            <Select
              tagRender={tagRender}
              options={urgencyDegree}
              placeholder="Selecione..."
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24} lg={9}>
          <Form.Item label="Responsável:" name="userId">
            <Input
              placeholder="Responsável"
              readOnly
              defaultValue={user.name}
            />
          </Form.Item>
        </Col>

        <Col span={24} lg={9}>
          <Form.Item label="Operador:" name="operatorId">
            <Select
              showSearch
              placeholder="Operador"
              optionFilterProp="children"
              filterOption={filterOption}
              options={operators}
            />
          </Form.Item>
        </Col>

        <Col span={24} lg={6}>
          <Form.Item label="Data da ocorrência:" name="occurredAt">
            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(undefined, {})(FormComponent);
