import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerCellsState,
  FETCH_CELL,
  FETCH_CELLS,
  CellsActionTypes,
} from './types';

const initialState: IReducerCellsState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'cells',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: CellsActionTypes) => {
  switch (action.type) {
    case FETCH_CELLS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_CELL:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
