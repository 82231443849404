import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Notification } from 'src/utils';
import { ICreateTaskInput } from 'src/interfaces/task';
import { ICell } from 'src/interfaces/cell';
import { ISector } from 'src/interfaces/sector';

import { IProps } from './create.type';

const useTask = (props: IProps) => {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [patrimonies, setPatrimonies] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [operators, setOperators] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);
  const [cells, setCells] = useState<ICell[] | undefined>(undefined);
  const [sectors, setSectors] = useState<ISector | undefined>(undefined);

  const handleSubmit = async (values: ICreateTaskInput | any) => {
    setSending(true);

    values.stage = Number(searchParams.get('stage')) || 0;

    try {
      const data = await props.createTask(values);

      if (!!data) {
        Notification.success({
          title: 'Sucesso',
          message: 'Tarefa cadastrada com sucesso',
        });

        navigate(-1);
      }
    } finally {
      setSending(false);
    }
  };

  const handlePatrimonies = async () => {
    setLoading(true);

    try {
      const response = await props.fetchPatrimonies();

      const options: { value: any; label: any }[] = response?.map(
        (item: any) => ({
          value: item.id,
          label: `${item.number || '-'} | ${item.description} (M: ${
            item.model
          }) (SN: ${item.serialNumber})`,
        })
      );

      if (options) {
        setPatrimonies(options);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de patrimônios, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleCells = async () => {
    setLoading(true);

    try {
      const response = await props.fetchCells();

      if (response) {
        setCells(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de células, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleOperators = async () => {
    setLoading(true);

    try {
      const response = await props.fetchOperators();

      if (response) {
        setOperators(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de operadores, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSectors = async () => {
    setLoading(true);

    try {
      const response = await props.fetchSectors();

      if (response) {
        setSectors(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de setores, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePatrimonies,
    patrimonies,
    handleCells,
    cells,
    handleOperators,
    operators,
    handleSectors,
    sectors,
    handleSubmit,
    sending,
    loading,
    navigate,
  };
};

export default useTask;
