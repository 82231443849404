import { Avatar } from 'antd';
import { wordWrap } from 'src/utils';

export const columns = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Foto',
    dataIndex: 'image',
    key: 'image',
    render: (text: any, record: any) => <Avatar>{record.name[0]}</Avatar>,
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: any) => (
      <div style={{ cursor: 'pointer' }}>
        <p className="mb-0">
          <b>{wordWrap(record.name, 18)}</b>
        </p>
        <small>({record.email})</small>
      </div>
    ),
  },
] as any;
