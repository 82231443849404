import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  FETCH_BOARD_LISTS,
  ADD_BOARD_LIST,
  MOVE_BOARD_LIST,
  DELETE_BOARD_LIST,
  ADD_LIST,
  CHANGE_LIST_TITLE,
  DELETE_LIST,
  ADD_LIST_CARD,
  MOVE_LIST_CARD,
  DELETE_LIST_CARD,
  ADD_CARD,
  CHANGE_CARD_TEXT,
  DELETE_CARD,
  DELETE_CARDS_LIST,
  CLEAR_BOARD,
  IReducerBoardState,
  BoardActionTypes,
} from './types';

const initialState: IReducerBoardState = {
  lists: ['0', '1', '2', '3'],
  listsById: {
    '0': { _id: '0', title: 'PENDENTE', cards: [] },
    '1': { _id: '1', title: 'EM PROGRESSO', cards: [] },
    '2': { _id: '2', title: 'FINALIZADO', cards: [] },
    '3': { _id: '3', title: 'EM PAUSA', cards: [] },
  },
  cardsById: [],
};

const persistConfig = {
  storage,
  key: 'board',
  whitelist: ['lists', 'listsById', 'cardsById'],
};

const reducers = (state = initialState, action: BoardActionTypes) => {
  switch (action.type) {
    // BOARD
    case FETCH_BOARD_LISTS:
      return {
        ...state,
        lists: action.payload,
      };
    case ADD_BOARD_LIST: {
      const { listId } = action.payload;
      return { ...state, lists: [...state.lists, listId] };
    }
    case MOVE_BOARD_LIST: {
      const { oldListIndex, newListIndex } = action.payload;
      const newLists = Array.from(state.lists);
      const [removedList] = newLists.splice(oldListIndex, 1);
      newLists.splice(newListIndex, 0, removedList);
      return { ...state, lists: newLists };
    }
    case DELETE_BOARD_LIST: {
      const { listId } = action.payload;
      const filterDeleted = (tmpListId: any) => tmpListId !== listId;
      const newLists = state.lists.filter(filterDeleted);
      return { ...state, lists: newLists };
    }

    // LIST
    case ADD_LIST: {
      const { listId, listTitle } = action.payload;
      const { listsById } = state;

      return {
        ...state,
        listsById: {
          ...listsById,
          [listId]: { _id: listId, title: listTitle, cards: [] },
        },
      };
    }
    case CHANGE_LIST_TITLE: {
      const { listId, listTitle } = action.payload;
      const { listsById } = state;
      return {
        ...state,
        listsById: {
          ...listsById,
          [listId]: { ...listsById[listId], title: listTitle },
        },
      };
    }
    case DELETE_LIST: {
      const { listId } = action.payload;
      const { listsById } = state;
      const { [listId]: deletedList, ...restOfLists } = listsById;

      return { ...state, listsById: restOfLists };
    }
    case ADD_LIST_CARD: {
      const { listId, cardId } = action.payload;
      const { listsById } = state;

      return {
        ...state,
        listsById: {
          ...listsById,
          [listId]: {
            ...listsById[listId],
            cards: [...listsById[listId].cards, cardId],
          },
        },
      };
    }
    case MOVE_LIST_CARD: {
      const { oldCardIndex, newCardIndex, sourceListId, destListId } =
        action.payload;
      const { listsById } = state;

      // Move within the same list
      if (sourceListId === destListId) {
        const newCards = Array.from(listsById[sourceListId].cards);
        const [removedCard] = newCards.splice(oldCardIndex, 1);
        newCards.splice(newCardIndex, 0, removedCard);
        return {
          ...state,
          listsById: {
            ...listsById,
            [sourceListId]: { ...listsById[sourceListId], cards: newCards },
          },
        };
      }

      // Move card from one list to another
      const sourceCards = Array.from(listsById[sourceListId].cards);
      const [removedCard] = sourceCards.splice(oldCardIndex, 1);
      const destinationCards = Array.from(listsById[destListId].cards);
      destinationCards.splice(newCardIndex, 0, removedCard);

      return {
        ...state,
        listsById: {
          ...listsById,
          [sourceListId]: { ...listsById[sourceListId], cards: sourceCards },
          [destListId]: { ...listsById[destListId], cards: destinationCards },
        },
      };
    }
    case DELETE_LIST_CARD: {
      const { deletedCardId, listId } = action.payload;
      const filterDeleted = (cardId: string) => cardId !== deletedCardId;
      const { listsById } = state;

      return {
        ...state,
        listsById: {
          ...listsById,
          [listId]: {
            ...listsById[listId],
            cards: listsById[listId].cards.filter(filterDeleted),
          },
        },
      };
    }

    // CARD
    case ADD_CARD: {
      const { cardText, cardId, ...rest } = action.payload;
      const { cardsById } = state;

      return {
        ...state,
        cardsById: {
          ...cardsById,
          [cardId]: { text: cardText, _id: cardId, ...rest },
        },
      };
    }
    case CHANGE_CARD_TEXT: {
      const { card, cardId } = action.payload;
      const { cardsById } = state;

      return {
        ...state,
        cardsById: {
          ...cardsById,
          [cardId]: { ...cardsById[cardId], text: cardId, ...card },
        },
      };
    }
    case DELETE_CARD: {
      const { cardId } = action.payload;
      const { cardsById } = state;
      const { [cardId]: deletedCard, ...restOfCards } = cardsById;
      return restOfCards;
    }
    case DELETE_CARDS_LIST: {
      const { cards: cardIds }: any = action.payload;
      const { cardsById } = state;

      const resCardsById = Object.keys(cardsById)
        .filter((id: string) => !cardIds.includes(id))
        .reduce(
          (newState, cardId) => ({
            ...newState,
            [cardId]: cardsById[cardId],
          }),
          {}
        );

      return {
        ...state,
        cardsById: resCardsById,
      };
    }

    case CLEAR_BOARD: {
      return {
        lists: ['0', '1', '2', '3'],
        listsById: {
          '0': { _id: '0', title: 'PENDENTE', cards: [] },
          '1': { _id: '1', title: 'EM PROGRESSO', cards: [] },
          '2': { _id: '2', title: 'FINALIZADO', cards: [] },
          '3': { _id: '3', title: 'EM PAUSA', cards: [] },
        },
        cardsById: [],
      };
    }

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
