import React from 'react';
import { Alert } from 'antd';

import { IProps } from './notifications.type';
import { Wrapper, Link } from './notifications.style';

const NotificationsComponent: React.FC<IProps> = (props) => {
  const { onEditing } = props;

  return (
    <Wrapper>
      <Alert
        className="mb-2"
        message="Você precisa atribuir um ou mais técnico(s) para iniciar a ordem de serviço."
        type="warning"
      />
      <Link onClick={() => onEditing(true)}>Atribuir técnico(s)</Link>
    </Wrapper>
  );
};

export default NotificationsComponent;
