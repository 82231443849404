import { api } from './';

const SectorsService = {
  all: async () => {
    const res = await api.get('/sectors?limit=1000');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/sectors/${id}`);
    return res.data;
  },
};

export default SectorsService;
