import { ITask } from 'src/interfaces/task';

export const rules = {
  description: [{ required: true }],
  stage: [{ required: true }],
  urgencyDegree: [{ required: true }],
  occurredAt: [{ required: true }],
  patrimonyId: [{ required: true }],
  operatorId: [{ required: true }],
  cellId: [{ required: true }],
  status: [{ required: true }],
  sectorId: [{ required: true }],
};

export const initialValues = (task?: ITask) => ({
  description: task?.description || '',
  stage: task?.stage || '',
  urgencyDegree: task?.urgencyDegree,
  patrimonyId: task?.patrimonyId || '',
  operatorId: task?.operatorId || '',
  cellId: task?.cellId || '',
  status: task?.status || '',
  sectorId: task?.sector?.id || '',
});
