import { useState } from 'react';

import { Notification } from 'src/utils';
import { IPatrimony } from 'src/interfaces/patrimony';

import { IProps } from './patrimonies.type';

const usePatrimonies = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [patrimonies, setPatrimonies] = useState<IPatrimony[] | undefined>(
    undefined
  );

  const handlePatrimonies = async () => {
    setLoading(true);

    try {
      const response = await props.fetchPatrimonies();

      if (response) {
        setPatrimonies(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de patrimônios, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handlePatrimonies, loading, patrimonies };
};

export default usePatrimonies;
