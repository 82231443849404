export const rules = {
  description: [{ required: true }],
  stage: [{ required: true }],
  urgencyDegree: [{ required: true }],
  occurredAt: [{ required: true }],
  patrimonyId: [{ required: true }],
  operatorId: [{ required: true }],
  cellId: [{ required: true }],
  status: [{ required: true }],
};
