import styled from 'styled-components';

export const List = styled.div`
  background: #dfe3e6;
  flex-shrink: 0;
  width: 352px;
  height: fit-content;
  margin: 10px;
  margin-right: 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const ListTitle = styled.div`
  padding: 10px;
  overflow-wrap: break-word;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-align: center;
`;

export const ListBody = styled.div`
  height: fit-content;
  max-height: 60vh;
  overflow-y: auto;
  padding: 2px;

  ::-webkit-scrollbar {
    background: white;
    border-radius: 5px;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #6b808c;
    border-radius: 10px;
  }
`;

export const Toggle = styled.div`
  cursor: pointer;
  padding: 10px;
  color: #6b808c;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  font-weight: 500;

  &:hover {
    background-color: rgba(9, 45, 66, 0.13);
    color: #17394d;
  }
`;
