import React from 'react';
import { Row, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { LogoutOutlined } from '@ant-design/icons';
import { FiAlignLeft } from 'react-icons/fi';

import { logout } from 'src/store/auth/actions';

import WrapperComponent from './components/wrapper/wrapper.component';
import { IProps } from './header.type';
import styles, { Title, Text } from './header.style';

const LayoutHeader: React.FC<IProps> = (props) => {
  const { handleDrawer, user } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  return (
    <div className="site-layout-background p-0">
      <WrapperComponent />
      <Row justify="space-between" style={styles.header} align="middle">
        <Button onClick={handleDrawer} style={styles.button}>
          <FiAlignLeft className="align-middle" color="white" />
        </Button>

        <div>
          {!isMobile && (
            <Title>
              Bem-vindo <b> {(!!user && user.name) || 'Administrador'}</b>
            </Title>
          )}

          <Tooltip title="Sair">
            <Button
              onClick={handleLogout}
              style={{ ...styles.dropdown, ...styles.button }}
              className="me-3"
            >
              <LogoutOutlined style={styles.icon} className="me-1" />
              <Text>Sair</Text>
            </Button>
          </Tooltip>
        </div>
      </Row>
    </div>
  );
};

export default connect(undefined, { logout })(LayoutHeader);
