import { useState } from 'react';

import { Notification } from 'src/utils';
import { ITask } from 'src/interfaces/task';

import { IProps } from './update-task.type';

const useTask = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState<ITask | undefined>(undefined);

  const handleTask = async (id: string) => {
    setLoading(true);

    try {
      const response = await props.fetchTask(id);

      if (response) {
        setTask(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a tarefa, tente novamente mais tarde.',
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleTask, loading, task };
};

export default useTask;
