import React from 'react';
import { Row, Col } from 'antd';

import { Title, Subtitle } from './title.style';
import { IProps } from './title.type';

const TitleComponent: React.FC<IProps> = (props) => {
  const { title, subtitle, foreword, className } = props;

  return (
    <Row className={`${className}`}>
      <Col span={24}>
        <Title foreword={foreword}>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Col>
    </Row>
  );
};

export default TitleComponent;
