import { api } from './';
import { ICreateTaskInput, IUpdateTaskInput } from 'src/interfaces/task';

const TasksService = {
  all: async () => {
    const res = await api.get('/tasks');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/tasks/${id}`);
    return res.data;
  },
  create: async (input: ICreateTaskInput) => {
    const res = await api.post('/tasks', input);
    return res.data;
  },
  update: async (id: string, input: IUpdateTaskInput) => {
    const res = await api.put(`/tasks/${id}`, input);
    return res.data;
  },
  remove: async (id: string) => {
    const res = await api.delete(`/tasks/${id}`);
    return res.data;
  },
};

export default TasksService;
