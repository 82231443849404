import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import { IReducerConfigState } from './config/types';
import { IReducerAuthState } from './auth/types';
import { IReducerBoardState } from './board/types';
import { IReducerTasksState } from './tasks/types';
import { IReducerTechniciansState } from './technicians/types';
import { IReducerPatrimoniesState } from './patrimonies/types';
import { IReducerCellsState } from './cells/types';
import { IReducerOperatorsState } from './operators/types';
import { IReducerSectorsState } from './sectors/types';

export interface IReduxState {
  config: IReducerConfigState;
  auth: IReducerAuthState;
  board: IReducerBoardState;
  tasks: IReducerTasksState;
  technicians: IReducerTechniciansState;
  patrimonies: IReducerPatrimoniesState;
  cells: IReducerCellsState;
  operators: IReducerOperatorsState;
  sectors: IReducerSectorsState;
}

const middleware = [thunk];

export const store = createStore(
  rootReducer,
  undefined,
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middleware)
    : require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(...middleware)
      )
);

export const persistor = persistStore(store);

export default store;
