import { IOperator } from 'src/interfaces/operator';

export const FETCH_OPERATORS = 'FETCH_OPERATORS';
export const FETCH_OPERATOR = 'FETCH_OPERATOR';

export interface IReducerOperatorsState {
  all?: IOperator[] | {value: any, label: any}[];
  current?: IOperator;
}

export interface IFetchOperators {
  type: typeof FETCH_OPERATORS;
  payload: IOperator[] | {value: any, label: any}[];
}

export interface IFetchOperator {
  type: typeof FETCH_OPERATOR;
  payload: IOperator;
}

export type OperatorsActionTypes = IFetchOperators | IFetchOperator;
