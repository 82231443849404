import {
  IoReorderFourSharp,
  IoReorderThreeSharp,
  IoReorderTwoSharp,
} from 'react-icons/io5';

interface IConstant {
  label: string;
  value: string | number;
  color?: string;
  icon?: React.ReactElement;
}

export const urgencyDegree: IConstant[] = [
  {
    label: 'BAIXO',
    value: 0,
    color: '#03a9f4',
    icon: <IoReorderTwoSharp size={11} className="me-1" />,
  },
  {
    label: 'ALTO',
    value: 1,
    color: '#ff9800',
    icon: <IoReorderThreeSharp size={11} className="me-1" />,
  },
  {
    label: 'MUITO ALTO',
    value: 2,
    color: '#e91e63',
    icon: <IoReorderFourSharp size={11} className="me-1" />,
  },
  {
    label: 'CRÍTICO',
    value: 3,
    color: '#f44336',
    icon: <IoReorderFourSharp size={11} className="me-1" />,
  },
  {
    label: 'NENHUM',
    value: 4,
    color: '#666',
    icon: undefined,
  },
];

export const urgencyDegreeColor: any = {
  0: '#03a9f4',
  1: '#ff9800',
  2: '#e91e63',
  3: '#f44336',
  4: '#666',
};

export const status: IConstant[] = [
  { label: 'APPROVED', value: 0, color: '#4caf50' },
  { label: 'REVIEWING', value: 1, color: '#3f51b5' },
  { label: 'REJECTED', value: 2, color: '#f44336' },
];

export const stage: IConstant[] = [
  { label: 'TODO', value: 0 },
  { label: 'DOING', value: 1 },
  { label: 'DONE', value: 2 },
  { label: 'ICEBODX', value: 3 },
];

export const stageTranslation: IConstant[] = [
  { label: 'PENDENTES', value: 0 },
  { label: 'EM PROGRESSO', value: 1 },
  { label: 'FINALIZADO', value: 2 },
  { label: 'EM PAUSA', value: 3 },
];
