import { ITask } from 'src/interfaces/task';

export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASK = 'FETCH_TASK';

export interface IReducerTasksState {
  all?: ITask[];
  current?: ITask;
}

export interface IFetchTasks {
  type: typeof FETCH_TASKS;
  payload: ITask[];
}

export interface IFetchTask {
  type: typeof FETCH_TASK;
  payload: ITask;
}

export type TasksActionTypes = IFetchTasks | IFetchTask;
