import { Route } from 'react-router-dom';

import BoardScreen from 'src/screens/board/board.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const boardRouteGroup = (
  <Route path="board">
    <Route index element={<PrivateRoute children={<BoardScreen />} />} />
  </Route>
);

export default boardRouteGroup;
