import React from 'react';
import { Alert, Form, Input, Button } from 'antd';
import { connect } from 'react-redux';

import { IProps } from './advice.type';
import { Wrapper, Message } from './advice.style';
import { fetchTask, updateTask } from 'src/store/tasks/actions';
import useTask from './advice.use';

const AdviceComponent: React.FC<IProps> = (props) => {
  const { task, listId } = props;

  const { editing, onEditing, sending, handleSubmit } = useTask(props);

  return (
    <Wrapper>
      {editing ? (
        <Alert
          className="mt-3 mb-2"
          description={
            <Form id="form-tech" layout="vertical" onFinish={handleSubmit}>
              <Form.Item name="technical_advice">
                <Input.TextArea
                  rows={4}
                  placeholder="Informe o parecer técnico da orderm de serviço..."
                  maxLength={6}
                />
              </Form.Item>

              <Button
                size="small"
                type="default"
                htmlType="submit"
                loading={sending}
              >
                <small>SALVAR</small>
              </Button>
            </Form>
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      ) : (
        <Alert
          className="mt-3 mb-2"
          description={
            !task.technical_advice && listId === 1 ? (
              <Message onClick={onEditing}>Adicionar parecer técnico</Message>
            ) : (
              task.technical_advice || 'Não informado'
            )
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      )}
    </Wrapper>
  );
};

export default connect(undefined, { fetchTask, updateTask })(AdviceComponent);
