import { ISector } from 'src/interfaces/sector';

export const FETCH_SECTORS = 'FETCH_SECTORS';
export const FETCH_SECTOR = 'FETCH_SECTOR';

export interface IReducerSectorsState {
  all?: ISector[];
  current?: ISector;
}

export interface IFetchSectors {
  type: typeof FETCH_SECTORS;
  payload: ISector[];
}

export interface IFetchSector {
  type: typeof FETCH_SECTOR;
  payload: ISector;
}

export type SectorsActionTypes = IFetchSectors | IFetchSector;
