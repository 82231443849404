import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { IProps } from './form-technicians.type';

import { ITask, IUpdateTaskInput } from 'src/interfaces/task';

const useFormTechnicians = (props: IProps) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);

  const { cardId } = props;

  const handleSubmit = async (values: IUpdateTaskInput | any) => {
    setSending(true);
    let i: number = 0;

    const input: ITask | any = {
      technicians: [],
    };

    for await (const item of values.technicians) {
      input.technicians.push({
        id: Number(item),
        principal: !i,
      });

      i++;
    }

    try {
      const data = await props.updateTask(cardId, input);

      if (!!data) {
        props.handleTask(cardId);

        dispatch({
          type: 'CHANGE_CARD_TEXT',
          payload: { cardId: cardId, cardText: input },
        });

        props.handleEditing(false);
      }
    } finally {
      setSending(false);
    }
  };

  return { handleSubmit, sending };
};

export default useFormTechnicians;
