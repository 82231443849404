import { api } from './';

const PatrimoniesService = {
  all: async () => {
    const res = await api.get('/patrimonies');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/patrimonies/${id}`);
    return res.data;
  },
  // create: async (input: ICreateExampleInput) => {
  //   const res = await api.post('/example', input);
  //   return res.data;
  // },
  // update: async (id: string, input: IUpdateExampleInput) => {
  //   const res = await api.patch(`/example/${id}`, input);
  //   return res.data;
  // },
  // remove: async (id: string) => {
  //   const res = await api.delete(`/example/${id}`);
  //   return res.data;
  // },
};

export default PatrimoniesService;
