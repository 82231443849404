import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerOperatorsState,
  FETCH_OPERATOR,
  FETCH_OPERATORS,
  OperatorsActionTypes,
} from './types';

const initialState: IReducerOperatorsState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'operators',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: OperatorsActionTypes) => {
  switch (action.type) {
    case FETCH_OPERATORS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_OPERATOR:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
