import { api } from './';

const CellsService = {
  all: async () => {
    const res = await api.get('/cells');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/cells/${id}`);
    return res.data;
  },
};

export default CellsService;
