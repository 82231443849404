import { Route } from 'react-router-dom';

import TechniciansScreen from 'src/screens/technicians/technicians.component';
// import TechniciansShowScreen from 'src/screens/technicians/show/show.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const techniciansRouteGroup = (
  <Route path="operadores-tecnicos">
    <Route index element={<PrivateRoute children={<TechniciansScreen />} />} />
    <Route
      path=":id"
      element={<PrivateRoute children={<TechniciansScreen />} />}
    />
  </Route>
);

export default techniciansRouteGroup;
