import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const EditWrapper = styled.div``;

export const EditCard = styled.div`
  min-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Textarea = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-size: 13px !important;
  height: auto !important;
  max-height: 50px !important;
  min-height: 40px !important;
  border-radius: 5px !important;
  background: white !important;
  padding: 5px 8px !important;
`;

export const ButtonGroup = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
`;

export const Button = styled.div`
  cursor: pointer;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 20%);
  width: fit-content;
  margin-bottom: 10px;
  padding: 4px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  outline: none;
  font-size: 13px;

  &:hover {
    opacity: 0.7;
  }

  &.save {
    background-color: #4caf50;
  }

  &.delete {
    background-color: #ef5350;
  }

  &.cancel {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 20px;
    opacity: 0.5;
    background-color: #9e9e9e;
    &:hover {
      opacity: 1;
    }
  }
`;
