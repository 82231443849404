import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineIdcard,
  AiOutlineBarcode,
} from 'react-icons/ai';

import { DiTrello } from 'react-icons/di';

import { logout } from 'src/store/auth/actions';
import { Notification, images } from 'src/utils';

import Divider from './components/divider/divider.component';
import { IProps } from './sider.type';
import { LogoIcon } from './sider.style';

const LayoutSider: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { collapsed } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  const collapsedTitle = (text: string, collapsed: boolean) => {
    if (!collapsed) {
      return text;
    }
    return;
  };

  return (
    <>
      <Link to="/">
        <LogoIcon
          src={images.icons.default}
          alt="logo"
          title="logo"
          width="45px"
        />
      </Link>

      <Menu theme="dark" mode="inline" defaultSelectedKeys={['2']}>
        <Divider content={collapsedTitle('Dashboard', collapsed)} noBorder />
        <Menu.Item
          key="2"
          icon={<DiTrello />}
          onClick={() => navigate('/painel/board/')}
        >
          Tarefas
        </Menu.Item>

        <Divider content={collapsedTitle('Geral', collapsed)} noBorder />
        <Menu.Item
          key="8"
          icon={<AiOutlineIdcard />}
          onClick={() => navigate('/painel/operadores-tecnicos/')}
        >
          Técnicos
        </Menu.Item>
        <Menu.Item
          key="9"
          icon={<AiOutlineBarcode />}
          onClick={() => navigate('/painel/patrimonios/')}
        >
          Patrimônios
        </Menu.Item>

        <Divider
          content={collapsedTitle('Configurações', collapsed)}
          noBorder
        />
        <Menu.Item
          key="7"
          icon={<AiOutlineSetting />}
          onClick={() => Notification.info({ message: 'Em desenvolvimento' })}
        >
          Minha Conta
        </Menu.Item>
        <Menu.Item key="10" icon={<AiOutlineLogout />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </>
  );
};

export default connect(undefined, { logout })(LayoutSider);
