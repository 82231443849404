import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IReduxState } from 'src/store';
import { Notification } from 'src/utils';
import { ITask, TaskStage } from 'src/interfaces/task';

import { IProps } from './board.type';

const useBoard = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<ITask[] | undefined>(undefined);
  const dispatch = useDispatch();
  const listsById = useSelector((state: IReduxState) => state.board.listsById);

  const handleTasks = async () => {
    setLoading(true);

    dispatch({
      type: 'CLEAR_BOARD',
    });

    try {
      const response = await props.fetchTasks();

      if (response) {
        setTasks(response);
        boardSeeder(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar as tarefas, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const boardSeeder = async (data: ITask[]) => {
    for await (const item of data) {
      let card = {
        listId: item.stage,
        cardId: `${item.id}`,
        cardText: `#${item.id}`,
        ...item,
      };

      dispatch({
        type: 'ADD_LIST_CARD',
        payload: card,
      });

      dispatch({
        type: 'ADD_CARD',
        payload: card,
      });
    }
  };

  const updateStageTask = async (taskId: string, stage: TaskStage) => {
    try {
      const response = await props.updateStageTask(taskId, stage);

      console.log(response);
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar as tarefas, tente novamente mais tarde.',
      });

      return false;
    }
  };

  const handleDragEnd = ({ source, destination }: any) => {
    if (!destination) return;

    if (
      source.index !== destination.index ||
      source.droppableId !== destination.droppableId
    ) {
      let taskId: any = Array.from(listsById[source.droppableId].cards).splice(
        source.index,
        1
      )[0];

      updateStageTask(taskId, destination.droppableId);

      dispatch({
        type: 'MOVE_LIST_CARD',
        payload: {
          sourceListId: source.droppableId,
          destListId: destination.droppableId,
          oldCardIndex: source.index,
          newCardIndex: destination.index,
        },
      });
    }
  };

  return { handleTasks, loading, tasks, handleDragEnd };
};

export default useBoard;
