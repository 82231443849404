import styled from 'styled-components';
import { Card } from 'src/components';

export const Wrapper = styled.div`
  background: rgb(121 174 192);
  height: 100vh;
`;

export const Header = styled(Card)`
  border-radius: 10px;

  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Board = styled.div`
  height: 92%;
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    background: #dfe3e6;
    border-radius: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #6b808c;
    border-radius: 10px;
  }
`;

export const AddListWrapper = styled.div`
  width: 272px;
  margin: 10px;
  flex-shrink: 0;
`;

export const AddListButton = styled.div`
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 5px 8px;
  transition: background-color 85ms ease-in, opacity 40ms ease-in,
    border-color 85ms ease-in;
  height: fit-content;

  &:hover {
    background-color: rgba(0, 0, 0, 0.24);
  }

  svg {
    margin-right: 1px;
  }
`;
