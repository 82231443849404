import styled from 'styled-components';

export const LogoIcon = styled.img`
  display: block;
  height: auto;
  margin: 20px auto 20px;
`;

const Default = {
  sideMenu: {
    borderRight: 0,
    height: '100%',
  },
};
export default Default;
