import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { IoIosAdd } from 'react-icons/io';
import { Droppable } from 'react-beautiful-dnd';

import { IReduxState } from 'src/store';

import Card from './components/card/card.component';

import { IProps } from './list.type';
import { List, ListTitle, ListBody, Toggle } from './list.style';

const ListComponent: React.FC<IProps> = (props) => {
  const { list } = props;

  const navigate = useNavigate();

  return (
    <List>
      <ListTitle>{list.title}</ListTitle>

      <Droppable droppableId={list._id}>
        {(provided: any, _snapshot: any) => (
          <ListBody ref={provided.innerRef}>
            {list?.cards &&
              list?.cards.map((cardId: string, index: number) => (
                <Card
                  key={cardId}
                  cardId={cardId}
                  index={index}
                  listId={list._id}
                />
              ))}

            {provided.placeholder}
          </ListBody>
        )}
      </Droppable>

      <Toggle
        onClick={() => navigate(`/painel/tarefas/nova?stage=${list._id}`)}
      >
        <IoIosAdd className="mx-1" size={16} />
        Adicionar tarefa
      </Toggle>
    </List>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: IProps) => ({
  list: state.board.listsById?.[ownProps.listId],
});

export default connect(mapStateToProps)(ListComponent);
