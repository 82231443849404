import { Dispatch } from 'react';

import PatrimoniesService from 'src/services/patrimonies.service';

import { IPatrimony } from 'src/interfaces/patrimony';

import { request } from 'src/utils';

import {
  FETCH_PATRIMONIES,
  FETCH_PATRIMONY,
  PatrimoniesActionTypes,
} from './types';

export const fetchPatrimonies =
  () => async (dispatch: Dispatch<PatrimoniesActionTypes>) => {
    try {
      const response: IPatrimony[] = await PatrimoniesService.all();

      dispatch({
        type: FETCH_PATRIMONIES,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchPatrimony =
  (id: string) => async (dispatch: Dispatch<PatrimoniesActionTypes>) => {
    try {
      const response: IPatrimony = await PatrimoniesService.current(id);

      dispatch({
        type: FETCH_PATRIMONY,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
