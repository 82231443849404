import { api } from './';

const TechniciansService = {
  all: async () => {
    const res = await api.get('/technicians');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/technicians/${id}`);
    return res.data;
  },
};

export default TechniciansService;
