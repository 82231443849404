import { ICell } from 'src/interfaces/cell';

export const FETCH_CELLS = 'FETCH_CELLS';
export const FETCH_CELL = 'FETCH_CELL';

export interface IReducerCellsState {
  all?: ICell[];
  current?: ICell;
}

export interface IFetchCells {
  type: typeof FETCH_CELLS;
  payload: ICell[];
}

export interface IFetchCell {
  type: typeof FETCH_CELL;
  payload: ICell;
}

export type CellsActionTypes = IFetchCells | IFetchCell;
