import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Row, Col, Skeleton, Button } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Container, Title } from 'src/components';
import { IReduxState } from 'src/store';
import { fetchTasks, updateStageTask } from 'src/store/tasks/actions';

import List from './components/list/list.component';

import { Wrapper, Header, Board } from './board.style';
import { IProps } from './board.type';
import useBoard from './board.use';

const BoardComponent: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleTasks, handleDragEnd, loading } = useBoard(props);
  const { board } = props;

  useEffect(() => {
    handleTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Wrapper>
      <Container type="fluid" className="mt-4">
        <Row gutter={8}>
          <Col span={24}>
            <Header className="m-0 p-0">
              <Title
                foreword="Ordem de serviço"
                title="Quadro de ordem de serviços"
                className="p-0 text-center d-inline"
              />
              <Button
                className="d-inline"
                type="primary"
                size="small"
                onClick={() => navigate('/painel/tarefas/nova')}
              >
                Adicionar Nova
              </Button>
            </Header>

            {!loading ? (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  droppableId="board"
                  direction="horizontal"
                  type="COLUMN"
                >
                  {(provided: any, _snapshot: any) => (
                    <Board ref={provided.innerRef}>
                      {board?.lists?.map((listId: string, index: number) => {
                        return (
                          <List listId={listId} key={listId} index={index} />
                        );
                      })}
                    </Board>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Header>
                <Skeleton active />
              </Header>
            )}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state: IReduxState) => ({ board: state.board });

export default connect(mapStateToProps, { fetchTasks, updateStageTask })(
  BoardComponent
);
