import {
  IAddListInput,
  IChangeListTitleInput,
  IList,
  IMoveBoardListInput,
  ICard,
} from 'src/interfaces/board';

export interface IReducerBoardState {
  lists: IList[] | any;
  listsById: IList[] | any;
  cardsById: ICard[] | any;
}

export const FETCH_BOARD_LISTS = 'FETCH_BOARD_LISTS';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const MOVE_BOARD_LIST = 'MOVE_BOARD_LIST';
export const DELETE_BOARD_LIST = 'DELETE_BOARD_LIST';
export const CLEAR_BOARD = 'CLEAR_BOARD';

export interface IFetchBoardLists {
  type: typeof FETCH_BOARD_LISTS;
  payload: IList[];
}

export interface IAddBoardList {
  type: typeof ADD_BOARD_LIST;
  payload: IList;
}

export interface IMoveBoardList {
  type: typeof MOVE_BOARD_LIST;
  payload: IMoveBoardListInput;
}

export interface IDeleteBoardList {
  type: typeof DELETE_BOARD_LIST;
  payload: IList;
}

// LIST
export const ADD_LIST = 'ADD_LIST';
export const CHANGE_LIST_TITLE = 'CHANGE_LIST_TITLE';
export const DELETE_LIST = 'DELETE_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const MOVE_LIST_CARD = 'MOVE_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';

export interface IAddList {
  type: typeof ADD_LIST;
  payload: IAddListInput;
}

export interface IChangeListTitle {
  type: typeof CHANGE_LIST_TITLE;
  payload: IChangeListTitleInput;
}

export interface IDeleteList {
  type: typeof DELETE_LIST;
  payload: { listId: string };
}

export interface IAddListCard {
  type: typeof ADD_LIST_CARD;
  payload: {
    listId: string;
    cardId: string;
  };
}

export interface IMoveListCard {
  type: typeof MOVE_LIST_CARD;
  payload: {
    oldCardIndex: number;
    newCardIndex: number;
    sourceListId: string;
    destListId: string;
  };
}

export interface IDeleteListCard {
  type: typeof DELETE_LIST_CARD;
  payload: { deletedCardId: string; listId: string };
}

// CARD
export const ADD_CARD = 'ADD_CARD';
export const CHANGE_CARD_TEXT = 'CHANGE_CARD_TEXT';
export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARDS_LIST = 'DELETE_CARDS_LIST';

export interface IAddCard {
  type: typeof ADD_CARD;
  payload: {
    cardId: string;
    cardText: string;
  };
}

export interface IChangeCardText {
  type: typeof CHANGE_CARD_TEXT;
  payload: {
    cardId: string;
    cardText: string;
    card: any;
  };
}

export interface IDeleteCard {
  type: typeof DELETE_CARD;
  payload: {
    cardId: string;
  };
}

export interface IDeleteCardsList {
  type: typeof DELETE_CARDS_LIST;
  payload: {
    cards: [];
  };
}

export interface IClearBoard {
  type: typeof CLEAR_BOARD;
}

export type BoardActionTypes =
  | IFetchBoardLists
  | IAddBoardList
  | IMoveBoardList
  | IDeleteBoardList
  | IAddList
  | IChangeListTitle
  | IDeleteList
  | IAddListCard
  | IMoveListCard
  | IDeleteListCard
  | IAddCard
  | IChangeCardText
  | IDeleteCard
  | IDeleteCardsList
  | IClearBoard;
