import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormInstance, Form, Skeleton } from 'antd';

import { Container, Card, Title, GoBack, FormFinish } from 'src/components';
import { createTask } from 'src/store/tasks/actions';
import { fetchCells } from 'src/store/cells/actions';
import { fetchOperators } from 'src/store/operators/actions';
import { fetchPatrimonies } from 'src/store/patrimonies/actions';
import { fetchSectors } from 'src/store/sectors/actions';
import { ICreateTaskInput } from 'src/interfaces/task';

import FormComponent from './components/form/form.component';
import { IProps } from './create.type';
import useTask from './create.use';

const formRef: React.Ref<FormInstance<ICreateTaskInput>> | any =
  React.createRef();

const CreateTaskScreen: React.FC<IProps> = (props) => {
  const {
    sending,
    operators,
    patrimonies,
    loading,
    handleSubmit,
    handleCells,
    handleOperators,
    handlePatrimonies,
    handleSectors,
  } = useTask(props);

  useEffect(() => {
    handleCells();
    handleOperators();
    handlePatrimonies();
    handleSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Tarefas"
            title="Adicione uma nova ordem de serviço"
            subtitle="Preencha o formulário abaixo para criar uma nova ordem de serviço que ficará localizada no quadro de tarefas."
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-5">
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit} ref={formRef}>
            {loading ? (
              <Card>
                <Skeleton active />
              </Card>
            ) : (
              <div>
                <FormComponent
                  operators={operators}
                  patrimonies={patrimonies}
                />
                <FormFinish sending={sending} />
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, {
  createTask,
  fetchCells,
  fetchOperators,
  fetchPatrimonies,
  fetchSectors,
})(CreateTaskScreen);
