import { Dispatch } from 'react';

import SectorsService from 'src/services/sectors.service';

import { ISector } from 'src/interfaces/sector';
import { request } from 'src/utils';

import {
  FETCH_SECTORS,
  FETCH_SECTOR,
  SectorsActionTypes,
} from './types';

export const fetchSectors =
  () => async (dispatch: Dispatch<SectorsActionTypes>) => {
    try {
      const response: ISector[] = await SectorsService.all();

      dispatch({
        type: FETCH_SECTORS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchSector =
  (id: string) => async (dispatch: Dispatch<SectorsActionTypes>) => {
    try {
      const response: ISector = await SectorsService.current(id);

      dispatch({
        type: FETCH_SECTOR,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
