import { Tag } from 'antd';

import { ClockCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

export const columns = [
  {
    title: 'Número',
    dataIndex: 'number',
    key: 'number',
    render: (text?: number) => text || '-',
  },
  {
    title: 'Descrição',
    dataIndex: 'number',
    key: 'number',
    render: (text: any, record: any) => {
      return (
        <div>
          <p className="m-0">
            <b>{record?.description || 'Indisponível'}</b>
          </p>
          <small className="d-block mb-0">
            <b>Serial Number: </b> {record?.serialNumber || '-'}
          </small>
          <small className="d-block mb-0">
            <b>Modelo: </b> {record?.model || '-'}
          </small>
        </div>
      );
    },
  },
  {
    title: 'Estoque',
    dataIndex: 'amount',
    key: 'amount',
    render: (text?: number) => text || '0',
  },
  {
    title: 'Setor',
    dataIndex: 'sector',
    key: 'sector',
    render: (text: any, record: any) => {
      return (
        <Tag color="default">
          <b>{record.sector?.name || 'Indisponível'}</b>
        </Tag>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'situation',
    key: 'situation',
    render: (text?: 'em_uso' | 'emprestada' | 'inativa') => {
      switch (text) {
        case 'em_uso':
          return (
            <Tag color="processing">
              <b>EM USO</b>
            </Tag>
          );
        case 'emprestada':
          return (
            <Tag icon={<ClockCircleOutlined />} color="warning">
              <b>EMPRESTADA</b>
            </Tag>
          );
        case 'inativa':
          return (
            <Tag icon={<MinusCircleOutlined />} color="default">
              <b>INATIVA</b>
            </Tag>
          );
        default:
          return (
            <Tag color="default">
              <b>-</b>
            </Tag>
          );
      }
    },
  },
] as any;
