import { ITechnician } from 'src/interfaces/technician';

export const FETCH_TECHNICIANS = 'FETCH_TECHNICIANS';
export const FETCH_TECHNICIAN = 'FETCH_TECHNICIAN';

export interface IReducerTechniciansState {
  all?: ITechnician[];
  current?: ITechnician;
}

export interface IFetchTechnicians {
  type: typeof FETCH_TECHNICIANS;
  payload: ITechnician[];
}

export interface IFetchTechnician {
  type: typeof FETCH_TECHNICIAN;
  payload: ITechnician;
}

export type TechniciansActionTypes = IFetchTechnicians | IFetchTechnician;
