import { Route } from 'react-router-dom';

import TasksScreen from 'src/screens/tasks/tasks.component';
import CreateTaskScreen from 'src/screens/tasks/create/create.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const tasksRouteGroup = (
  <Route path="tarefas">
    <Route index element={<PrivateRoute children={<TasksScreen />} />} />
    <Route path=":id" element={<PrivateRoute children={<TasksScreen />} />} />
    <Route
      path="nova"
      element={<PrivateRoute children={<CreateTaskScreen />} />}
    />
  </Route>
);

export default tasksRouteGroup;
