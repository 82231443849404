import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { IUpdateTaskInput } from 'src/interfaces/task';

import { IProps } from './advice.type';

const useTask = (props: IProps) => {
  const dispatch = useDispatch();

  const [sending, setSending] = useState(false);
  const [editing, setEditing] = useState(false);

  const { handleEditing, cardId } = props;

  const onEditing = () => {
    setEditing(!editing);
  };

  const handleSubmit = async (values: IUpdateTaskInput | any) => {
    setSending(true);

    try {
      const data = await props.updateTask(cardId, values);

      if (!!data) {
        handleEditing(false);

        dispatch({
          type: 'CHANGE_CARD_TEXT',
          payload: { cardId: cardId, cardText: values },
        });

        setEditing(false);
      }
    } finally {
      setSending(false);
    }
  };

  return {
    editing,
    onEditing,
    handleSubmit,
    sending,
  };
};

export default useTask;
