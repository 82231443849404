import styled from 'styled-components';

export const TitleWrapper = styled.div`
  background-color: #dfe3e6;
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  b {
    font-size: 20px;
  }
`;

export const ListTitleWrapper = styled.span`
  font-weight: 400;
  font-size: 16px;
  b {
    text-decoration: underline;
  }
`;
