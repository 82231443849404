import { useState } from 'react';

import { Notification } from 'src/utils';

import { IProps } from './technicians.type';
import { ITechnician } from 'src/interfaces/technician';
import { Avatar } from 'antd';

const useTechnicians = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState<ITechnician[] | undefined>(
    undefined
  );

  const handleTechnicians = async () => {
    setLoading(true);
    setTechnicians(undefined);

    try {
      const response = await props.fetchTechnicians();
      const options: any = [];

      if (response?.length) {
        for await (const item of response) {
          options.push({
            label: item.name,
            value: item.id,
            emoji: <Avatar size="large">{item.name[0] || ''}</Avatar>,
            desc: item.name,
          });
        }

        setTechnicians(options);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar os técnicos, tente novamente mais tarde.',
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleTechnicians, loading, technicians };
};

export default useTechnicians;
