import { Dispatch } from 'react';

import TechniciansService from 'src/services/technicians.service';

import { ITechnician } from 'src/interfaces/technician';

import { request } from 'src/utils';

import {
  FETCH_TECHNICIANS,
  FETCH_TECHNICIAN,
  TechniciansActionTypes,
} from './types';

export const fetchTechnicians =
  () => async (dispatch: Dispatch<TechniciansActionTypes>) => {
    try {
      const response: ITechnician[] = await TechniciansService.all();

      dispatch({
        type: FETCH_TECHNICIANS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchTechnician =
  (id: string) => async (dispatch: Dispatch<TechniciansActionTypes>) => {
    try {
      const response: ITechnician = await TechniciansService.current(id);

      dispatch({
        type: FETCH_TECHNICIAN,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
