import { Route } from 'react-router-dom';

import PatrimoniesScreen from 'src/screens/patrimonies/patrimonies.component';
// import PatrimoniesShowScreen from 'src/screens/patrimonies/show/show.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const patrimoniesRouteGroup = (
  <Route path="patrimonios">
    <Route index element={<PrivateRoute children={<PatrimoniesScreen />} />} />
    <Route
      path=":id"
      element={<PrivateRoute children={<PatrimoniesScreen />} />}
    />
  </Route>
);

export default patrimoniesRouteGroup;
