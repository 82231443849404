import React, { useEffect } from 'react';
import { Row, Col, Table, Skeleton } from 'antd';
import { connect } from 'react-redux';

import { Card, Container, Title, GoBack } from 'src/components';
import { fetchPatrimonies } from 'src/store/patrimonies/actions';


import { IProps } from './patrimonies.type';
import { columns } from './patrimonies.utils';
import usePatrimonies from './patrimonies.use';

const PatrimoniesScreen: React.FC<IProps> = (props) => {
  const { handlePatrimonies, patrimonies, loading } = usePatrimonies(props);

  useEffect(() => {
    handlePatrimonies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Patrimônios"
            title="Ver todos os patrimônios"
            subtitle="Listagem de patrimônios relacionado a este setor."
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            {!loading ? (
              <Table
                bordered
                size="small"
                rowKey={(record: any) => record.id}
                columns={columns}
                dataSource={patrimonies}
              />
            ) : (
              <Skeleton active />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { fetchPatrimonies })(PatrimoniesScreen);
