import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--primary-color);
  padding: 5px;
`;

export const Logo = styled.img`
  display: block;
  height: auto;
  max-width: 200px;
  margin: 20px auto;

  @media (max-width: 992px) {
    max-width: 130px;
  }
`;

export const Title = styled.span`
  color: #eee;
  margin-right: 15px;
  font-size: 12px;
  letter-spacing: 0.03em;
`;

export const Text = styled.p`
  display: inline;
  font-size: 12px;
  margin: 0;
`;

const Default = {
  header: {
    background: '#191919',
    boxShadow: '1px 1px 10px 1px rgb(0 0 0 / 20%)',
    padding: 20,
  },
  headerLogin: {
    background: 'rgb(0 0 0 / 70%)',
    borderBottom: 0,
    padding: '0 20px',
  },
  button: {
    fontSize: 20,
    background: 'transparent',
    border: 0,
    padding: 0,
    verticalAlign: 'middle',
    borderRadius: 0,
    color: 'white',
  },
  icon: {
    verticalAlign: 'inherit',
  },
  dropdown: {
    fontSize: 20,
    background: 'transparent',
    verticalAlign: 'middle',
    border: '1px solid #eee',
    padding: '0 10px',
    borderRadius: 2,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default Default;
