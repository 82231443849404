import { Dispatch } from 'react';

import OperatorService from 'src/services/operators.service';

import { IOperator } from 'src/interfaces/operator';
import { request } from 'src/utils';

import {
  FETCH_OPERATORS,
  FETCH_OPERATOR,
  OperatorsActionTypes,
} from './types';

export const fetchOperators =
  () => async (dispatch: Dispatch<OperatorsActionTypes>) => {
    try {
      const response: {data: IOperator[]} = await OperatorService.all();

      const options : {value: any, label: any}[] = response?.data?.map((item: any) => ({
        value: item.id,
        label: `${item.id} | ${item.name}`,
      }));

      dispatch({
        type: FETCH_OPERATORS,
        payload: options,
      });

      return options;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchOperator =
  (id: string) => async (dispatch: Dispatch<OperatorsActionTypes>) => {
    try {
      const response: IOperator = await OperatorService.current(id);

      dispatch({
        type: FETCH_OPERATOR,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
