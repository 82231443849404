import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerSectorsState,
  FETCH_SECTOR,
  FETCH_SECTORS,
  SectorsActionTypes,
} from './types';

const initialState: IReducerSectorsState = {
  all: undefined,
  current: undefined,
};

const persistConfig = {
  storage,
  key: 'sectors',
  whitelist: ['all'],
};

const reducers = (state = initialState, action: SectorsActionTypes) => {
  switch (action.type) {
    case FETCH_SECTORS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_SECTOR:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
