import { combineReducers } from 'redux';

import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import boardReducer from './board/reducers';
import tasksReducer from './tasks/reducers';
import techniciansReducer from './technicians/reducers';
import patrimoniesReducer from './patrimonies/reducers';
import cellsReducer from './cells/reducers';
import operatorsReducer from './operators/reducers';
import sectorsReducer from './sectors/reducers';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  board: boardReducer,
  tasks: tasksReducer,
  technicians: techniciansReducer,
  patrimonies: patrimoniesReducer,
  cells: cellsReducer,
  operators: operatorsReducer,
  sectors: sectorsReducer,
});
