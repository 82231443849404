import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Drawer } from 'antd';
import { isBrowser } from 'react-device-detect';

import { IReduxState } from 'src/store';

import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import Menu from './components/sider/sider.component';

import { IProps } from './layout.type';
import styles from './layout.style';

const LayoutComponent: React.FC<IProps> = (props) => {
  const { children, user } = props;
  const [collapsed, setCollapsed] = useState(true);
  const [visible, setVisible] = useState(false);
  const { Sider } = Layout;

  const handleDrawer = () => {
    setCollapsed(!collapsed);
    setVisible(!visible);
  };

  const mobileView: React.ReactElement = (
    <Layout style={{ position: 'relative', minHeight: '95vh' }}>
      {!!user ? (
        <>
          <Drawer
            placement="left"
            open={visible}
            closable={false}
            onClose={() => setVisible(false)}
          >
            <Menu collapsed={collapsed} />
          </Drawer>
          <Layout style={styles.contentLayout}>
            <Header handleDrawer={handleDrawer} user={user} />
            {children}
            <Footer />
          </Layout>
        </>
      ) : (
        <Layout style={styles.contentLayoutLogin}>{children}</Layout>
      )}
    </Layout>
  );

  const browserView: React.ReactElement = (
    <Layout style={{ position: 'relative', minHeight: '100vh' }}>
      {!!user ? (
        <>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={styles.sider}
          >
            <Menu collapsed={collapsed} />
          </Sider>
          <Layout style={styles.contentLayout}>
            <Header handleDrawer={handleDrawer} user={user} />
            {children}
            <Footer />
          </Layout>
        </>
      ) : (
        <Layout style={styles.contentLayoutLogin}>{children}</Layout>
      )}
    </Layout>
  );

  return isBrowser ? browserView : mobileView;
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(LayoutComponent);
