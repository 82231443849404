import { useState } from 'react';

import { Notification } from 'src/utils';
import { ITechnician } from 'src/interfaces/technician';

import { IProps } from './technicians.type';

const useTechnicians = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState<ITechnician[] | undefined>(
    undefined
  );

  const handleTechnicians = async () => {
    setLoading(true);

    try {
      const response = await props.fetchTechnicians();

      if (response) {
        setTechnicians(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar os operadores técnicos , tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleTechnicians, loading, technicians };
};

export default useTechnicians;
