import { Route } from 'react-router-dom';

import boardRouteGroup from './groups/board.routes';
import tasksRouteGroup from './groups/tasks.routes';
import techniciansRouteGroup from './groups/patrimonies.routes';
import patrimoniesRouteGroup from './groups/technicians.routes';

const userRoutes = (
  <Route path="painel">
    {boardRouteGroup}
    {tasksRouteGroup}
    {techniciansRouteGroup}
    {patrimoniesRouteGroup}
  </Route>
);

export default userRoutes;
