import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Notification } from 'src/utils';
import { ITask, IUpdateTaskInput } from 'src/interfaces/task';

import { IProps } from './form.type';

interface ISelectOptions {
  value: string;
  label: string;
}

const useTask = (props: IProps) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [task, setTask] = useState<ITask | undefined>(undefined);
  const [patrimonies, setPatrimonies] = useState<ISelectOptions[] | undefined>(
    undefined
  );
  const [operators, setOperators] = useState<ISelectOptions[] | undefined>(
    undefined
  );
  const [cells, setCells] = useState<ISelectOptions[] | undefined>(undefined);
  const [sectors, setSectors] = useState<ISelectOptions[] | undefined>(
    undefined
  );

  const { handleEditing, cardId } = props;

  const handleTask = async (id: string) => {
    setLoading(true);

    try {
      const response = await props.fetchTask(id);

      if (response) {
        setTask(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a tarefa, tente novamente mais tarde.',
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: IUpdateTaskInput | any) => {
    setSending(true);

    try {
      const data = await props.updateTask(cardId, values);

      if (!!data) {
        handleEditing();

        dispatch({
          type: 'CHANGE_CARD_TEXT',
          payload: { cardId: cardId, cardText: values },
        });
      }
    } finally {
      setSending(false);
    }
  };

  const handlePatrimonies = async () => {
    setLoading(true);

    try {
      const response = await props.fetchPatrimonies();

      const options: ISelectOptions[] = response?.map((item: any) => ({
        value: item.id,
        label: `${item.number || '-'} | ${item.description} (M: ${
          item.model
        }) (SN: ${item.serialNumber})`,
      }));

      if (options) {
        setPatrimonies(options);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de patrimônios, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleCells = async () => {
    setLoading(true);

    try {
      const response = await props.fetchCells();

      const options: ISelectOptions[] = response?.data?.map((item: any) => ({
        value: item.id,
        label: item.name || '-',
      }));

      if (options) {
        setCells(options);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de células, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleOperators = async () => {
    setLoading(true);

    try {
      const response = await props.fetchOperators();

      if (response) {
        setOperators(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de operadores, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSectors = async () => {
    setLoading(true);

    try {
      const response = await props.fetchSectors();

      const options: ISelectOptions[] = response?.map((item: any) => ({
        value: item.id,
        label: item.name || '-',
      }));

      if (options) {
        setSectors(options);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar a listagem de setores, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePatrimonies,
    patrimonies,
    handleCells,
    cells,
    handleOperators,
    operators,
    handleSectors,
    sectors,
    handleSubmit,
    loading,
    handleTask,
    task,
    sending,
  };
};

export default useTask;
