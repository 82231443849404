import { api } from './';

const OperatorsService = {
  all: async () => {
    const res = await api.get('/operators?limit=9999');
    return res.data;
  },
  current: async (id: string) => {
    const res = await api.get(`/operators/${id}`);
    return res.data;
  },
};

export default OperatorsService;
