import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  b {
    font-size: 20px;
  }
`;

export const Message = styled.span`
  text-decoration: underline;
  display: block;
  cursor: pointer;
`;
