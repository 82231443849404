import React, { useEffect } from 'react';
import { Row, Col, Table, Skeleton } from 'antd';
import { connect } from 'react-redux';

import { Card, Container, Title, GoBack } from 'src/components';

import { IProps } from './technicians.type';
import { columns } from './technicians.utils';
import useTechnicians from './technicians.use';
import { fetchTechnicians } from 'src/store/technicians/actions';

const TechnicianScreen: React.FC<IProps> = (props) => {
  const { handleTechnicians, technicians, loading } = useTechnicians(props);

  useEffect(() => {
    handleTechnicians();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Operadores"
            title="Ver todos os operadores técnicos"
            subtitle="Lista de todos os operadores técnicos cadastrados."
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            {!loading ? (
              <Table
                bordered
                size="small"
                rowKey={(record: any) => record.id}
                columns={columns}
                dataSource={technicians}
              />
            ) : (
              <Skeleton active />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { fetchTechnicians })(TechnicianScreen);
