import React, { useEffect } from 'react';
import { Row, Col, Table, Skeleton, Button } from 'antd';
import { ProjectTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { Card, Container, Title, GoBack } from 'src/components';
import { fetchTasks } from 'src/store/tasks/actions';

import { IProps } from './tasks.type';
import { columns } from './tasks.utils';
import useTasks from './tasks.use';

const TasksScreen: React.FC<IProps> = (props) => {
  const { handleTasks, loading } = useTasks(props);
  const navigate = useNavigate();

  useEffect(() => {
    handleTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-2" align="bottom" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Backlog"
            title="Ver todas as tarefas"
            subtitle="Listagem das tarefas em pendências."
          />
        </Col>
        <Col className="justify-content-end">
          <Button
            type="primary"
            onClick={() => navigate('/painel/tarefas/nova')}
          >
            <ProjectTwoTone size={22} />
            Cadastrar
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            {!loading ? (
              <Table
                bordered
                size="small"
                rowKey={(record: any) => record.id}
                columns={columns}
                dataSource={[]}
                pagination={false}
              />
            ) : (
              <Skeleton active />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(undefined, { fetchTasks })(TasksScreen);
