import React from 'react';
import { Skeleton, Select, Form, Button } from 'antd';
import { connect } from 'react-redux';

import { updateTask } from 'src/store/tasks/actions';

import { IProps } from './form-technicians.type';
import useFormTechnicians from './form-technicians.use';
// import { initialValues } from './form-technicians.utils';

const FormTechniciansComponent: React.FC<IProps> = (props) => {
  const { loading, technicians } = props;

  const { handleSubmit, sending } = useFormTechnicians(props);

  return loading ? (
    <Skeleton active />
  ) : (
    <Form id="form-tech" layout="vertical" onFinish={handleSubmit}>
      <Form.Item name="technicians">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Selecionar..."
          options={technicians}
        />
      </Form.Item>

      <Button size="small" type="default" htmlType="submit" loading={sending}>
        <small>SALVAR</small>
      </Button>
    </Form>
  );
};

export default connect(undefined, { updateTask })(FormTechniciansComponent);
