import { IPatrimony } from 'src/interfaces/patrimony';

export const FETCH_PATRIMONIES = 'FETCH_PATRIMONIES';
export const FETCH_PATRIMONY = 'FETCH_PATRIMONY';

export interface IReducerPatrimoniesState {
  all?: IPatrimony[];
  current?: IPatrimony;
}

export interface IFetchPatrimonies {
  type: typeof FETCH_PATRIMONIES;
  payload: IPatrimony[];
}

export interface IFetchPatrimony {
  type: typeof FETCH_PATRIMONY;
  payload: IPatrimony;
}

export type PatrimoniesActionTypes = IFetchPatrimonies | IFetchPatrimony;
